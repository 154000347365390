/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 1 oct. 2019, 13:07:31
    Author     : Sylvain
*/

.search-row {
    padding-top:15px;
}
.search-row .form-group .checkbox {
    margin-bottom: 10px;
}
.search-row .form-group .checkbox label span, 
.search-widget .checkbox label span
{
    vertical-align: middle;
    padding-left: 5px;
    font-weight: 700;
}
.checkbox label, .radio label {
    padding-left: 0px;
}
.search-row .form-group .slider-rent, .search-row .form-group .slider-sale {
    opacity: 0.2;
}
.form-inline .form-control, .form-inline .form-control {
    border-radius: 1px;
    color: midnightblue;
    height: 40px;
    width: 195px;
}
.large-search{
    padding: 25px 25px 40px;
}
.large-search .search-form {
    background-color: white;
    border-radius: 1px;
    padding: 20px 0px;
    box-sizing: border-box;
    position: relative;
    z-index: 9;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0);
    display: inline-block;
    width: 100%;
}
.large-search .search-form .form-inline .form-control, .form-inline .form-control,
.large-search .search-form .bootstrap-select button.dropdown-toggle ,
.large-search .search-form .bootstrap-select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){
    width:100%;
}
.form-group #message {
    height: 70px;
}
.form-inline input[type=submit]{
    background: midnightblue;
    color: white;
    height: 40px;
    width: 90px;
    border-radius: 1px;
    font-weight: bold;
    font-size: 16px;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    transition: all 0.4s ease;
}
.form-inline input[type=submit]:hover{
    background: #00A2DE
}
.form-control::-moz-placeholder{
    color: gray;
    opacity: 1;
}
.form-control:-moz-placeholder{
    color: gray;
    opacity: 1;  
}  
.form-control::-webkit-input-placeholder{
    color: gray;
    opacity: 1; 
} 
.form-control:-ms-input-placeholder{
    color: gray;
    opacity: 1; 
}
.form-control:focus {
    border-color: lightgray;
    outline: 0px none;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(102, 175, 233, 0);
}
 .form-result-ok {
    display :none;
    font-weight: bold;
}
.form-result-loading, .form-result-error {
    display :none;
}
.form-result-loading {
    padding-top: 10px;
}
.form-result-ok img{
    width: 40px;
    padding-left: 10px;
}
.form-result-loading {
    width:30px;
    height:30px;
    background-image:url(../img/loading.svg);  
    background-repeat:no-repeat;
    background-position:center;
    margin-left: auto;
    margin-right: auto;
}
.form-control, .input-group-addon{
    -webkit-transition: all 300ms linear;
    -moz-transition: all 300ms linear;
    -o-transition: all 300ms linear;
    -ms-transition: all 300ms linear;
    transition: all 300ms linear;
}

/* Inputs */

.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 13px;
    line-height: 1.42857;
    color: dimgray;
    background-color: white;
    background-image: none;
    border-radius: 0px;
    box-shadow: 0px 1px 1px rgba(169, 169, 169, 0.1) inset;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
}
.edit-property textarea.form-control {
    height: 76px;
}
.form-control:focus {
    background-color: white;
    border: 1px solid #9A9A9A;
    box-shadow: none;
    outline: 0 none;
}
.form-control + .form-control-feedback{
    border-radius: 6px;
    font-size: 14px;
    padding: 0 12px 0 0;
    position: absolute;
    right: 25px;
    top: 13px;
    vertical-align: middle;
}
.form-control:focus + .input-group-addon, .form-control:focus ~ .input-group-addon{
    background-color: white;
    border-color: darkgray;
}
.form-submit .form-result-ok,.form-result-error{
    display: none;
}
.form-submit .btn i {
    margin-right: 5px;
}
.input-group .form-control:first-child, .input-group-addon:first-child, .input-group-btn:first-child > .btn, .input-group-btn:first-child > .dropdown-toggle, .input-group-btn:last-child > .btn:not(:last-child):not(.dropdown-toggle) {
    border-right: 0 none;
}
.input-group .form-control:last-child, .input-group-addon:last-child, .input-group-btn:last-child > .btn, .input-group-btn:last-child > .dropdown-toggle, .input-group-btn:first-child > .btn:not(:first-child) {
    border-left: 0 none;
}
.search-btn ,.toggle-btn{
    background-color: midnightblue !important;
    color: white;
    font-size: 16px;
    border: 1px solid midnightblue !important;
    width: 40px;
    height: 40px;
    transition: all 0.3s ease 0s; 
    padding: 0px;
}
.search-btn:hover, .toggle-btn:hover {
    color: white;
    background-color: slateblue !important;
    border-color: slateblue !important;
}
.dropdown-toggle:hover, dropdown-toggle:hover {
    color: white;
    background-color: white !important;
}
.btn.focus, .btn:focus, .btn:active {
    color: white;
    background-color: midnightblue !important;
}
.dropdown-toggle.focus, .dropdown-toggle:focus, .dropdown-toggle:active {
    color: white;
    background-color: white !important;
}
.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: lightgray;
    color: darkgray;
    cursor: not-allowed;
}
.has-success .form-control, 
.has-error .form-control, 
.has-success .form-control:focus, 
.has-error .form-control:focus{
    border-color: lightgray;
    box-shadow: none;
}
.has-success .form-control, 
.has-success .form-control-feedback,
.form-control.valid:focus{
    border-color: green;
    color: green;
}
.has-error .form-control, 
.has-error .form-control-feedback,
.form-control.error{
    color: red;
    border-color: red;
}

form label.required:after
{
    color: red;
    content: " *";
}

.grecaptcha-badge {
    position: absolute !important;
}