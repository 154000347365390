/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19 juil. 2019, 16:11:25
    Author     : Sylvain
*/

.main-content {
    position: relative;
}

.img-main {
    height: auto;
    width: 100%;
    vertical-align: middle;
    border: 0px none; 
}

#homepage .top-menu {
    margin-bottom: 16px !important;
}

#combo-localisation {
    margin-top: 6px;
}

@media (max-width: 767px) {
#homepage .top-menu {
    margin-bottom: 10px !important;
}

#homepage .top-menu {
    margin-bottom: 10px !important;
}
}

@media (max-width: 480px){
#homepage .main-content {
    position: relative;
}
}
@media (min-width: 481px){
.search-form {
    background-color: white;
    border-radius: 5px;
    padding: 20px 0;
    border: 1px solid #E6E6E6;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 9;
}
}